import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "What do Americans want for Christmas?",
  "description": "Different ages want different things. Create a horizontal stack chart showing what everyone wants for Christmas.",
  "date": "2018-12-06T08:00:00.000Z",
  "published": "2018-12-06T08:00:00.000Z",
  "image": "./what-americas-want.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <lite-youtube {...{
      "videoid": "pn45HcG1faM",
      "videostartat": "0"
    }}></lite-youtube>
    <p>{`Different ages want different things. Create a horizontal stack chart showing what everyone wants for Christmas.`}</p>
    <strong>Dataset: </strong>
    <a href={`./statistic_id643714_christmas-gifts-desired-by-us-consumers-2017-by-age-group.xlsx`}>
  Download dataset 🗳{" "}
    </a>
    <h2 {...{
      "id": "my-solution-"
    }}>{`My solution 👇`}</h2>
    <iframe {...{
      "src": "https://codesandbox.io/embed/v874323625",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <h2 {...{
      "id": "how-it-works-️"
    }}>{`How it works ⚙️`}</h2>
    <p>{`Today's challenge is a perfect example of how `}<a parentName="p" {...{
        "href": "https://gka.github.io/chroma.js/"
      }}>{`chroma-js`}</a>{` automatically makes your dataviz beautiful. Best magic trick I ever learned from Shirley Wu.`}</p>
    <p>{`We used `}<a parentName="p" {...{
        "href": "https://d3-legend.susielu.com/"
      }}>{`Susie Lu's d3-legend`}</a>{` for the color legend, D3's stack layout to calculate coordinates for stacking those bar charts, D3 axis for the axis, and the rest was React. Similar code to the bar chart in `}<a parentName="p" {...{
        "href": "/christmas-movies/"
      }}>{`Christmas movies at the box office`}</a>{`.`}</p>
    <h2 {...{
      "id": "load-the-data"
    }}>{`Load the data`}</h2>
    <p>{`We begin once more by loading the data. If you've been following along so far, this code will look familiar.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`  componentDidMount() {
    d3.tsv("/data.tsv", d => ({
      category: d.category,
      young: Number(d.young),
      mid: Number(d.mid),
      old: Number(d.old)
    })).then(data => this.setState({ data }));
  }
`}</code></pre>
    <p><inlineCode parentName="p">{`d3.tsv`}</inlineCode>{` loads our tab separated values file with data, a parsing function turns each line into nice objects we can use, and then we save it into component local state.`}</p>
    <h2 {...{
      "id": "an-axis-and-a-legend"
    }}>{`An axis and a legend`}</h2>
    <p>{`Building axes and legends from scratch is not hard, but it is fiddly and time consuming and fraught with tiny little traps for you to fall into. No time for that on a daily challenge!`}</p>
    <p><a parentName="p" {...{
        "href": "https://d3blackbox.com"
      }}>{`d3blackbox`}</a>{` to the rescue!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const VerticalAxis = d3blackbox((anchor, props) => {
  const axis = d3.axisLeft().scale(props.scale)
  d3.select(anchor.current).call(axis)
})

const Legend = d3blackbox((anchor, props) => {
  d3.select(anchor.current).call(
    legend.legendColor().scale(props.scale).title("Age group")
  )
})
`}</code></pre>
    <p>{`Here you can see just how flexible the blackbox rendering approach I teach in `}<a parentName="p" {...{
        "href": "https://reactfordataviz.com"
      }}>{`React for Data Visualization`}</a>{` can be. You can take just about any D3 code and turn it into a React component.`}</p>
    <p>{`Means you don't have to write your own fiddly stuff 👌`}</p>
    <p><inlineCode parentName="p">{`d3blackbox`}</inlineCode>{` ensures our render functions are called on every component render and creates a positionable grouping, `}<inlineCode parentName="p">{`<g>`}</inlineCode>{`, SVG element for us to move around.`}</p>
    <h2 {...{
      "id": "each-categorys-barchart"
    }}>{`Each category's barchart`}</h2>
    <p>{`You can think a stacked bar chart as a series of barcharts. Each category gets its own.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const BarChart = ({ entries, y, width, marginLeft, color }) => (
  <React.Fragment>
    {entries.map(([min, max], i) => (
      <rect
        x={marginLeft + width(min)}
        width={width(max) - width(min)}
        y={y(y.domain()[i])}
        height={y.bandwidth()}
        key={y.domain()[i]}
        fill={color}
      >
        <title>
          {min}, {max}
        </title>
      </rect>
    ))}
  </React.Fragment>
)
`}</code></pre>
    <p>{`These barchart subcomponents are fully controled components. They help us clean up the rendering and don't need any logic of their own.`}</p>
    <p>{`Takes a list of `}<inlineCode parentName="p">{`entries`}</inlineCode>{` to render, a `}<inlineCode parentName="p">{`y`}</inlineCode>{` scale for vertical positioning, a `}<inlineCode parentName="p">{`width`}</inlineCode>{` scale to calculate widths, some margin on the left for the big axis, and a `}<inlineCode parentName="p">{`color`}</inlineCode>{` to use.`}</p>
    <p>{`Renders a React Fragment with a bunch of rectangles. Loop over the entries, return a positioned rectangle for each.`}</p>
    <p>{`Our entries are pairs of `}<inlineCode parentName="p">{`min`}</inlineCode>{` and `}<inlineCode parentName="p">{`max`}</inlineCode>{` values as calculated by the stack layout. We use them to decide the horizontal, `}<inlineCode parentName="p">{`x`}</inlineCode>{` position of our rectangle, and its width. Using the `}<inlineCode parentName="p">{`width`}</inlineCode>{` scale both times. That takes care of proper sizing for us.`}</p>
    <p>{`That `}<inlineCode parentName="p">{`key`}</inlineCode>{` prop is a little funny though.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`y`}</inlineCode>{` scale is an ordinal scale. Its domain is a list of categories, which means we can get the name of each bar's category by picking the right index out of that array. Perfect for identifying our elements :)`}</p>
    <h2 {...{
      "id": "a-stack-chart-built-with-react-and-d3"
    }}>{`A stack chart built with React and D3`}</h2>
    <p>{`Here's how all of that ties together 👇`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class StackChart extends React.Component {
  y = d3
    .scaleBand()
    .domain(this.props.data.map((d) => d.category))
    .range([0, this.props.height])
    .paddingInner(0.1)
  stack = d3.stack().keys(["young", "mid", "old"])
  color = chroma.brewer.pastel1
  colorScale = d3
    .scaleOrdinal()
    .domain(["🧒 18 to 29 years", "🙍‍♂️ 30 to 59 years", "🧓 60 years or older"])
    .range(this.color)

  render() {
    const { data } = this.props

    const stack = this.stack(data)

    const width = d3
      .scaleLinear()
      .domain([0, d3.max(stack[2], (d) => d[1])])
      .range([0, 400])

    return (
      <g>
        <VerticalAxis scale={this.y} x={220} y={0} />
        {this.stack(data).map((entries, i) => (
          <BarChart
            entries={entries}
            y={this.y}
            key={i}
            marginLeft={223}
            color={this.color[i]}
            width={width}
          />
        ))}
        <Legend scale={this.colorScale} x={500} y={this.props.height - 100} />
      </g>
    )
  }
}
`}</code></pre>
    <p>{`Okay that's a longer code snippet 😅`}</p>
    <h3 {...{
      "id": "d3-setup"
    }}>{`D3 setup`}</h3>
    <p>{`In the beginning, we have some D3 objects.`}</p>
    <ol>
      <li parentName="ol">{`A `}<inlineCode parentName="li">{`y`}</inlineCode>{` band scale. Handles vertical positioning, sizing, spacing, and all`}</li>
      <li parentName="ol">{`A `}<inlineCode parentName="li">{`stack`}</inlineCode>{` generator with hardcoded keys. We know what we want and there's no need to be fancy`}</li>
      <li parentName="ol">{`A `}<inlineCode parentName="li">{`color`}</inlineCode>{` list. Chroma's `}<inlineCode parentName="li">{`brewer.pastel1`}</inlineCode>{` looked Best`}</li>
      <li parentName="ol">{`A `}<inlineCode parentName="li">{`colorScale`}</inlineCode>{` with a more verbose domain and our list of colors as the range`}</li>
    </ol>
    <p>{`Having a separate list of colors and color scale is important. Our individual bars want a specific color, our legend wants a color scale. They use different domains and unifying them would be fiddly. Easier to keep apart.`}</p>
    <h3 {...{
      "id": "render"
    }}>{`render`}</h3>
    <p>{`We do a little cheating in our `}<inlineCode parentName="p">{`render`}</inlineCode>{` method. That `}<inlineCode parentName="p">{`stack`}</inlineCode>{` should be generated in a `}<inlineCode parentName="p">{`componentDidUpdate`}</inlineCode>{` of some sort and so should the `}<inlineCode parentName="p">{`width`}</inlineCode>{` linear scale.`}</p>
    <p>{`But our data is small so it's okay to recalculate all this every time.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`stack`}</inlineCode>{` generator creates a list of lists of entries. 3 lists, one for each category (age group). Each list contains pairs of numbers representing how they should stack.`}</p>
    <p>{`Like this`}</p>
    <pre><code parentName="pre" {...{}}>{`[
  [[0, 5], [0, 10]],
  [[5, 7], [10, 16]],
  [[13, 20], [26, 31]]
]
`}</code></pre>
    <p>{`Entries in the first list all begin at `}<inlineCode parentName="p">{`0`}</inlineCode>{`. Second list begins where the previous list ends. Third list begins where the second list ended. Stacking up as far as you need.`}</p>
    <p>{`Your job is then to take these numbers, feed them into some sort of scale to help with sizing, and render.`}</p>
    <p>{`That was our `}<inlineCode parentName="p">{`<BarChart>`}</inlineCode>{` sub component up above. It takes each list, feeds its values into a `}<inlineCode parentName="p">{`width`}</inlineCode>{` scale, and renders.`}</p>
    <p>{`Making sure we render 3 of them, one for each age group, is this part:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`return (
  <g>
    <VerticalAxis scale={this.y} x={220} y={0} />
    {stack.map((entries, i) => (
      <BarChart
        entries={entries}
        y={this.y}
        key={i}
        marginLeft={223}
        color={this.color[i]}
        width={width}
      />
    ))}
    <Legend scale={this.colorScale} x={500} y={this.props.height - 100} />
  </g>
)
`}</code></pre>
    <p>{`Starts by rendering an axis, followed by a loop through our stack, rendering a `}<inlineCode parentName="p">{`<BarChart>`}</inlineCode>{` for each, and then the `}<inlineCode parentName="p">{`<Legend>`}</inlineCode>{` component neatly positioned to look good.`}</p>
    <p>{`A beautiful chart pops out.`}</p>
    <p><img parentName="p" {...{
        "src": "./what-americas-want.png",
        "alt": "Beautiful chart"
      }}></img></p>
    <h2 {...{
      "id": "today-you-learned-🧐"
    }}>{`Today you learned 🧐`}</h2>
    <ul>
      <li parentName="ul">{`chroma-js exist and is amazing`}</li>
      <li parentName="ul">{`d3-legend for easy legends`}</li>
      <li parentName="ul">{`d3blackbox still saving the day`}</li>
      <li parentName="ul">{`D3 stack generator`}</li>
    </ul>
    <p>{`🤓`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      